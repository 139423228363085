<template>
  <v-img
  :src="previewURL"
  :height="imageHeight"
  :max-height="imageHeight"
  max-width="100%"
  cover
  >
   <v-container
    style="position:absolute;bottom:40%" v-if="contentType==='Music'||contentType==='Video'"
    >
    <v-row align="center" justify="space-around">
      <v-btn rounded>
      <v-icon color="blue" large>
      mdi-play-circle-outline
      </v-icon>
      </v-btn>
    </v-row>
  </v-container>
  </v-img>
</template>

<script>
import NFTCard from '@/features/nfts/nft-card/NFTCard';

export default {
  name: 'NFTCardHeader',
  data: () => ({
    playMusic: false,
  }),
  mixins: [NFTCard],
  methods: {
    play() { // play music
      this.$refs.audio.play();
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/variables';
i.v-icon.v-icon {
  color: red;
}
</style>
